.Table {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px auto;
}

.elementRemoved,
.elementAwaited {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  padding: 5px;
  margin: 2px;
  border-radius: 0.5rem;
  transition: all 0.3s;
  cursor: pointer;
}

.elementRemovedRedAndGreen,
.elementAwaitedRedAndGreen {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  color: #f9f9f9;
  padding: 5px;
  margin: 2px;
  border-radius: 0.5rem;
  transition: all 0.3s;
  cursor: pointer;
}